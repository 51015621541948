body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hanko-auth, hanko-profile {
    --color: #fff;
    --color-shade-1: #989BA1;
    --color-shade-2: #43464E;
    --brand-color: #AEDFFF;
    --brand-color-shade-1: #A1C9E7;
    --brand-contrast-color: #0B0D0E;
    --background-color: #262B35;
    --error-color: #FF2E4C;
    --link-color: #AEDFFF;

    /* Font Styles */
    --font-family: "IBM Plex Sans";
    --font-size: 1rem;
    --font-weight: 400;
    --headline1-font-size: 24px;
    --headline1-font-weight: 600;
    --headline2-font-size: 1rem;
    --headline2-font-weight: 600;

    --border-radius: 8px;

    /* Item Styles */
    --item-height: 40px;
    --item-margin: 8px 0px;

    /* Container Styles */
    --container-padding: 70px 55px 90px 55px;
    --container-max-width: 800px;
    --headline1-margin: 0 0 1rem;
    --headline2-margin: 1rem 0 .5rem;
}

hanko-profile {
    --container-padding: 0;
}
